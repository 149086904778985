window.openDrawer = function openDrawer() {
  var drawer = document.querySelector('.mp-drawer');
  var layout = document.querySelector('.mp-layout-normal');

  drawer.classList.add('is-open');
  layout.classList.add('no-scroll');
};

window.closeDrawer = function closeDrawer() {
  var drawer = document.querySelector('.mp-drawer');
  var layout = document.querySelector('.mp-layout-normal');

  drawer.classList.remove('is-open');
  layout.classList.remove('no-scroll');
}

window.closeFixedAds = function closeDrawer() {
  var ads = document.querySelector('.mp-fixed-ads');
  var screen = document.querySelector('.mp-fixed-ads-screen');

  ads.classList.add('close');
  screen.classList.add('close');
}

window.toggleDrawer = function toggleDrawer() {
  var drawer = document.querySelector('.mp-drawer');

  drawer.classList.contains('is-open') ? window.closeDrawer() : window.openDrawer();
}

window.searchBox = function searchBox(isOpen){
  if (isOpen) {
    document.querySelector('.mp-header').classList.add('search');
  } else
  {
    document.querySelector('.mp-header').classList.remove('search');
  }
}

